import firebase from 'firebase/app';
import 'firebase/auth';

const config = {
    apiKey: "AIzaSyD3a0dXTPaI_VWGcBNsQ-9mLOnlXu3sFoQ",
    authDomain: "katrinundtom-e7330.firebaseapp.com",
    databaseURL: "https://katrinundtom-e7330.firebaseio.com",
    storageBucket: "katrinundtom-e7330.appspot.com",
};

const fire = firebase.initializeApp(config);
export default fire;