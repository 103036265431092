import React, { Component } from 'react';
import './App.css';
import ContainerGroup from "./components/ContainerGroup";
import Gallery from "./components/Gallery";

class App extends Component {
    render() {
        return (
            <div className="main">
                <ContainerGroup/>
                <Gallery />
                <footer>
                    Stand: {process.env.REACT_APP_LAST_BUILD || '00.00.2018'}
                </footer>
            </div>
        );
    }
}

export default App;