import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import CountUp from 'react-countup';

export default class ContainerGroup extends React.Component {
    render() {
        return (
            <Container>
                <Row>
                    <Col>
                        <h2>Katrin &amp; Thomas Horster</h2>
                        <img className="img-circle" src="assets/rings.jpg" alt="Eheringe Katrin &amp; Tom" width="50%" />
                        <div className="countup">
                            <CountUp start={0} end={17} duration={4} />.
                            <CountUp start={0} end={11} duration={2} />.
                            <CountUp start={0} end={2018} duration={3} />
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <h2>Danke schön!</h2>
                        <p>
                            Wir möchten auf diesem Wege schon einmal allen Freunden und Verwandten DANKE sagen!<br/>
                            DANKE, dass ihr da ward und diesen Tag zu etwas wirklich ganz besonderem gemacht habt!
                            DANKE auch für all die tollen und liebevollen Geschenke, die ihr für uns hattet, wir haben uns über alle Maßen gefreut!
                        </p>
                    </Col>
                </Row>
            </Container>
        );
    }
}